export function getHomeRoute() {
    return '/';
}

export function getGamePageRoute() {
    return '/game';
}

export function getPolicyRoute() {
    return '/polityka-prywatnosci';
}

export function getAccessibilityRoute() {
    return '/dostepnosc';
}
